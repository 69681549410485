#Login {
    color: var(--body) !important;
    background-color: var(--success);
    border-color: var(--success);
}
#Login:hover , #Logout:hover{
    font-size: 0.95em;
}
#Login:active , #Logout:active{
    font-size: 0.93em;
}

#Logout {
    color: var(--body) !important;
    background-color: var(--danger);
    border-color: var(--danger);
}
@media (min-width: 992px){
    .contentloginModal {
        width: 50%;
        margin-left: 25%;
    }
}
