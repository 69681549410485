.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**{*/
/*    color:#C6CDB3;*/
/*}*/
*{
  --lightBlue : #9BB4DD;
  --whiteText : #F5F1EA;
  --blueGreenText : #239B56;
  --darkBlue : #3165CF;
  --body : #eee;
  --squares : #239B56;
}


@media (max-width: 992px) {
  .categoryName {
    font-size: 1.3rem !important;
  }
  .navbar-nav {
    flex-direction: row !important;
  }
  .fa-border {
    border-radius: 100%;
  }
}

/*@media (max-width: 600px) {*/
/*    .cases {*/
/*        justify-content: flex-start!important;*/
/*    }*/
/*}*/

body {
  background-color:var(--bodyCategoriesBackground);
  color:var(--bodyCategoriesColor);
  overflow-x: hidden;
}
*:not(img) {
  font-family: var(--defaultFont);
  max-width: 100vw !important;
}
.navbar {
  background-color: var(--headerBackground);
  color : var(--headerColor) !important;
  border-bottom: 2px solid var(--whiteText);
}
.nav-link:not(#previous2 , #next2) {
  color : var(--headerColor) !important;
}

.nav-link:not('#avatar') {
  opacity : .4;
}
.nav-link:not('#avatar'):hover {
  cursor: pointer;
  opacity: 1;
}
.current {
  opacity : 1 !important;
}

.navbar-brand {
  padding:0;
  border:0;
  margin:0;
}

#avatar {
  display: inline;
}
#avatar > img {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
}
#avatar:hover {
  opacity: .4 !important;

}
.nav-item > i {
  font-size:2em;
}
.jumbotron {
  text-align: center;
  background-color: var(--body);
  color: var(--blueGreenText);
  padding: 2rem 1rem;

}
.container {
  color: var(--bodyCategoriesColor);
}
#Categories > h2 {
  text-align: center;
}

.category {
  border: var(--CategoriesBorders);
  border-radius: var(--CategoriesRadiusBorders);
  text-align: center;
  color : var(--categoriesColor);
  background-color: var(--categoriesBackground);
  transition: background-color  500ms ease  , color 500ms ease ;
}
.category:hover {
  color : var(--bodyCategoriesBackground);
  background-color : var(--bodyCategoriesColor);
  cursor: pointer;

}
.categoryName:not(#categoryName) {
  font-size :2rem;
  word-spacing: -0.3rem;
}
.category > i {
  font-size: 4em;
}
/*.actualScore {*/
/*    color: var(--blueGreenText);*/
/*}*/
/*.actualScore:hover {*/
/*    color: var(--body);*/

/*}*/

#backHomePage {
  background-color:var(--categoriesBackground);
  border: var(--CategoriesBorders);
  border-radius: 5px;
  text-align: center;
  color : var(--categoriesColor);
  transition: background-color  500ms ease  , color 500ms ease ;
  text-decoration: none;
}
#backHomePage:hover {
  color : var(--categoriesBackground);
  background-color : var(--categoriesColor);
  cursor: pointer;
}
.fa-windows {
  display: inline-block;
  text-align: center;
  width:100%
  /*font-size: 15rem;*/
}
#quizPic {
  /*border:2px solid;*/
  /*border-radius: 100%;*/

}
.fa-border {
  border: 2px solid var(--blueGreenText);
}
.case , #clearButton {
  font-family: var(--Letters);
  color: var(--CasesColor);
  background-color: var(--CasesBackground);
  border: var(--CasesBorder);
  font-weight: bold;
  pointer-events: none!important;
}
#clearButton {
  font-size : 2rem;
}
.case {
  font-size: var(--casesSizeDesktop);
}

#clearButton{
  font-family: var(--Letters);
  color : var(--clearButtonColor);
  border: var(--CategoriesBorders);
  border-radius: var(--CategoriesRadiusBorders);
  background-color: var(--clearButtonBackground);
  font-weight: bold;
  font-size: 2rem;
  pointer-events: none!important;
}
.casePlain {
  background-color: #ace3c1 !important; ;
}
.caseEmpty {
  color: var(--body) !important;
}
.space {
  background-color: var(--body);

  border-color: var(--body)  !important;
}
.letter {
  font-family: var(--Letters);
  color: var(--LettersColor);
  background-color: var(--LettersBackground);
  border: var(--LettersBorder);
  font-weight: bold;
  font-size: var(--lettersSizeDesktop);
}
/*.cases {*/
/*    display: flex;*/
/*}*/
.letter {
  border-radius: var(--LettersBorderRadius);
}

.case {
  border-radius : var(--CasesBorderRadius)
}

#next , #previous {
  border-color: var(--blueGreenText);
  color: var(--blueGreenText);
  width: 120px;
  padding: 1rem 0.2rem;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: background-color  200ms ease  , color 200ms ease ;
}

#next:hover , #previous:hover {
  color: var(--whiteText);
  background-color: var(--blueGreenText);
}

.nextPrevContainer {
  padding: 0;
}
/*.fa-lightbulb {*/
/*  color: var(--lamp) !important;*/
/*}*/
/*.fa-circle{*/
/*  color: var(--lamp) !important;*/
/*}*/
.fa-stack {
  position : static;
}
.fa-arrow-left:hover {
  opacity: .3;
}
#hintIconContainer{
  position: relative;
  bottom: 50px;
}
#hintIconContainer:hover {
  cursor:pointer;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

@keyframes shakeChoice {
  10%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(20px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-20px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(20px, 0, 0);
  }
}
#trapezoid {
  border-bottom: 100px solid red;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 100px;
}
#QuizLevel {
  display: flex;
  flex-direction: column !important;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}
#previous2 , #next2 {
  color: var(--nextPrevQuestionColor) !important;
  padding:  0.25rem 0.5rem;
}

/*#previous2:hover  , #next2:hover {*/
/*    color: var(--body) !important;*/
/*    background-color: var(--blueGreenText) !important;*/
/*    border-radius: 30px;*/
/*    transition: background-color  500ms ease  , color 500ms ease ;*/
/*}*/
#previous2:active  , #next2:active{
  color: black !important;
  font-size: 0.9em;
}
a:hover {
  text-decoration: none !important;
}
.choice {
  border: var(--choicesBorders);
  border-radius: var(--choicesRadiusBorders);
  color: var(--choicesColor);
  background-color: var(--choicesBackground);
}

#choices1 , #choices2 {
  justify-items: flex-start;
  display: flex;
  justify-self: flex-start;
  /* justify-content: flex-end !important; */
  justify-content: space-evenly !important;
}
.choice {
  max-width: 200px !important;
  transition: background-color  500ms ease  , color 500ms ease ;
}


.choice:active {
  animation: shakeX 200ms;
}

.stage{
  border :2px solid var(--blueGreenText);
  padding-left:  1.35rem !important;
}
.stageState > p , .stageNumber>p {
  margin : 0;
}
.stageState {
  justify-content: space-between;
}
.progressStages {
  padding: 0;
}

.stageNumber , .stageState > p:nth-child(1) {
  color: #000;
  font-weight: 600;
}
.stageState>p:nth-child(2){
  font-weight: 600;
}

.stageNumber , .stageState {
  margin-left: -0.5rem !important;
}
.letter {
  padding: 0.5rem 0.7rem !important;
  margin-bottom : 0.25rem !important;
}
.navbar {
  justify-content: flex-start;
}

.choice {
  font-family: var(--Choices) !important;
}

#footerNavbar {
  bottom: -2px;

}
@media (max-width: 992px){
  #footerBrand {
    display: block;
    width: 100%;
    margin-left: 0 !important;

  }
  #navFooter{
    justify-content: center;
    width: 100%;
    font-size: 0.8rem;
    margin-left: 0 !important;
  }
  .footerItem{
    margin-left: 1rem !important;
    margin-right : 1rem !important;
  }
  #footerNavbar {
    height: 60px !important;

  }
}

.contactFormGroup {
  display: flex;
  flex-direction: column;
  align-items: start;
}
#contactForm {
  display: inline-flex;
  width: 80vw;
}

#submitContact {
    border-radius: 10px;
    padding: .5rem 1rem;
    background-color: var(--headerBackground) !important;
    color : var(--headerColor) !important;
}

@media (max-width: 768px){
  #navbarHeader {
    top:0px;
  }
}

@media (min-width: 768px){
  .modal-content {
    width: 400px;
      left: calc(50% - 200px);
  }
}

@media (max-width: 768px){
  #PrivacypolicyContainer{
    margin-top: 10.5rem !important;
  }
  #privacyPolicyTitle{
    font-size : 3.2rem;
  }
}

@media (min-width: 768px){
  #PrivacypolicyContainer{
    margin-top: 4rem !important;
  }
  #privacyPolicyTitle{
    font-size : 4.3rem;
  }
}